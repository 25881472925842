import { Injectable } from '@angular/core';
import { AbstractDialogService } from '@examdojo/core/dialog';
import { SupportedLanguage } from '@examdojo/core/i18n';
import { first, firstValueFrom, switchMap, tap } from 'rxjs';
import { LanguageService } from '../../shared/i18n/language.service';
import { LanguagePickerDialogComponent } from './language-picker-dialog.component';

@Injectable()
export class LanguagePickerDialogService extends AbstractDialogService<void, SupportedLanguage> {
  constructor(private readonly languageService: LanguageService) {
    super();
  }

  readonly id = 'language-picker-dialog';
  protected readonly component = LanguagePickerDialogComponent;

  protected override readonly options = {
    cssClass: 'modified-modal font-primary',
    initialBreakpoint: undefined,
  };

  override openDialog() {
    return firstValueFrom(
      this.languageService.activeLanguage$.pipe(
        first(),
        switchMap((lang) =>
          super.openDialog({ options: { ...this.options, componentProps: { initialSelection: lang } } }),
        ),
        tap((selectedLanguage) => {
          if (selectedLanguage) {
            this.languageService.setActiveLang(selectedLanguage);
          }
        }),
      ),
    );
  }
}
