<div class="mx-auto flex h-full flex-col">
  <div class="mx-auto mb-4 flex w-full items-center justify-between border-b border-gray-300 px-6 py-4">
    <a class="text-md font-semibold italic text-green-600" [routerLink]="DEFAULT_URL">Exam Dojo</a>
    <div *examIfUserHasRole="'CONTENT_CREATOR'" class="flex gap-2">
      <y42-button [routerLink]="['/', RootUrlParts.Cms, CmsUrlParts.Questions]">Questions</y42-button>
      <y42-button [routerLink]="['/', RootUrlParts.Cms, CmsUrlParts.ReferenceQuestions]">
        Reference questions
      </y42-button>
      <y42-button
        *ngIfFeatureFlag="ExamDojoFeatureFlag.Syllabus"
        [routerLink]="['/', RootUrlParts.Cms, CmsUrlParts.Syllabus]"
      >
        Syllabus
      </y42-button>
    </div>
    <div class="flex items-center gap-4">
      <y42-icon-button [iconSize]="20" icon="language" prefix="fas" (click)="openLanguageDialog()"></y42-icon-button>

      @if (state.signedIn) {
        <button mat-button (click)="logout()">{{ 'examdojo.sign_out' | transloco }}</button>
      }
    </div>
  </div>

  <div class="scrollbar-gutter-stable flex-1 overflow-y-auto pl-6 pr-4">
    <ng-content></ng-content>
  </div>
</div>
