import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { ButtonComponent } from '@examdojo/core/button';
import { FeatureFlagDirective } from '@examdojo/core/feature-flag';
import { IconButtonComponent } from '@examdojo/core/icon-button';
import { provideFaIcons } from '@examdojo/icons';
import { ExamDojoFeatureFlag } from '@examdojo/models/feature-flag';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons';
import { TranslocoPipe } from '@jsverse/transloco';
import { DEFAULT_URL, RootUrlParts } from '../app.model';
import { AuthService } from '../auth/auth.service';
import { CmsUrlParts } from '../cms/cms.model';
import { IfUserHasRoleDirective } from '../user/user-role/if-user-has-role.directive';
import { LanguagePickerDialogService } from './language-picker-dialog/language-picker-dialog.service';

@Component({
  selector: 'exam-shell',
  standalone: true,
  imports: [
    ButtonComponent,
    RouterLink,
    MatButton,
    IfUserHasRoleDirective,
    IconButtonComponent,
    TranslocoPipe,
    FeatureFlagDirective,
  ],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss',
  host: { class: 'h-full' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LanguagePickerDialogService],
})
export class ShellComponent {
  constructor(
    private readonly authService: AuthService,
    private readonly languagePickerDialogService: LanguagePickerDialogService,
  ) {
    provideFaIcons([faLanguage]);
  }

  readonly state = connectState({
    signedIn: this.authService.signedIn$,
  });

  readonly RootUrlParts = RootUrlParts;
  readonly CmsUrlParts = CmsUrlParts;
  readonly ExamDojoFeatureFlag = ExamDojoFeatureFlag;
  readonly DEFAULT_URL = DEFAULT_URL;

  logout() {
    this.authService.signOut();
  }

  openLanguageDialog() {
    this.languagePickerDialogService.openDialog();
  }
}
